import { clsx } from 'clsx';

type Props = {
  color?: 'purple' | 'blue' | 'green' | 'yellow' | 'dark';
  direction?: 'right' | 'down' | 'left';
  width?: string;
  height?: string;
  className?: string;
};
const arrowColor = {
  green: '#33FF71',
  yellow: '#DFF800',
  blue: '#75F3FB',
  purple: '#A498FF',
  dark: '#030310',
};
export const SmallArrowIcon = ({
  color = 'green',
  direction = 'right',
  height = '16',
  width = '16',
  className = '',
}: Props) => {
  if (direction === 'right') {
    return (
      <svg
        className={clsx(className, 'h-[clamp(16px, 1.111vw, 32px)] w-[clamp(18px, 1.181vw, 36px)]')}
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.8984 9.43994L0.0839845 9.43994L0.0839844 6.93994L14.8984 6.93994L14.8984 9.43994Z"
          fill={arrowColor[color]}
        />
        <path
          d="M8.13853 15.0563L14.2096 8.98518C14.6001 8.59466 14.6001 7.96149 14.2096 7.57097L8.13853 1.4999"
          stroke={arrowColor[color]}
          strokeWidth="2.5"
        />
      </svg>
    );
  } else if (direction === 'left') {
    return (
      <svg
        className={clsx(className, 'md:h-[1.111vw] h-[16px] md:w-[1.181vw] w-[18px]')}
        width={width}
        height={height}
        viewBox="0 0 12 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.76837e-07 5.49784C4.76837e-07 5.80247 0.0983272 6.05726 0.29498 6.26222L4.2255 10.4225C4.33025 10.5288 4.44158 10.61 4.55949 10.666C4.67739 10.722 4.79505 10.75 4.91245 10.75C5.20161 10.75 5.43517 10.6558 5.61315 10.4675C5.79113 10.2792 5.88012 10.0453 5.88012 9.76586C5.88012 9.60624 5.85057 9.46585 5.79147 9.34467C5.73236 9.22349 5.65696 9.11585 5.56526 9.02177L4.20301 7.5793L2.31135 5.73513L1.86124 6.39962L4.34729 6.53331H10.9716C11.2804 6.53331 11.529 6.43802 11.7174 6.24745C11.9058 6.05687 12 5.807 12 5.49784C12 5.18562 11.9058 4.93425 11.7174 4.74371C11.529 4.55317 11.2804 4.4579 10.9716 4.4579H4.34729L1.86124 4.59594L2.31135 5.2581L4.20301 3.41627L5.56526 1.97826C5.65696 1.88418 5.73236 1.7758 5.79147 1.65313C5.85057 1.53046 5.88012 1.38932 5.88012 1.22971C5.88012 0.950227 5.79113 0.717076 5.61315 0.530258C5.43517 0.34344 5.20161 0.25003 4.91245 0.25003C4.66379 0.25003 4.43708 0.357609 4.23233 0.572765L0.29498 4.73335C0.0983272 4.93533 4.76837e-07 5.19016 4.76837e-07 5.49784Z"
          fill="#030310"
        />
      </svg>
    );
  } else {
    return (
      <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 17 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.53906 17.5L6.53906 0.5H9.03906L9.03906 17.5H6.53906Z"
          fill={arrowColor[color]}
        />
        <path
          d="M1.00049 10.4995L7.05544 16.5545C7.44597 16.945 8.07913 16.945 8.46966 16.5545L15.2816 9.74253"
          stroke={arrowColor[color]}
          strokeWidth="2.5"
        />
      </svg>
    );
  }
};
