import React from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { Card } from './Card';
import { Icon1, Icon2, Icon3, Icon4, Icon5, Icon6, Icon7, Icon8 } from './icons-collection';
import { PATENT_LINK } from '@/constants/links';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { MobileSlider } from '@/components/MobileSlider/MobileSlider';

const CARDS_CONTENT = [
  {
    id: 0,
    icon: <Icon1 />,
    title: 'Custom AI Models',
    description:
      'Tailor the AI’s tone and behavior to suit your business, from sales-oriented to customer-centric.',
  },
  {
    id: 1,
    icon: <Icon2 />,
    title: 'Comprehensive AI Features',
    description:
      'Multilayer knowledge base, reinforcement learning, and accurate, context-aware responses.',
  },
  {
    id: 2,
    icon: <Icon3 />,
    title: 'Non-Hallucinating AI',
    description:
      'Unlike many AI platforms, CoSupport AI guarantees factual accuracy, preventing AI ‘hallucinations’ and ensuring you always provide correct information.',
  },
  {
    id: 3,
    icon: <Icon4 />,
    title: 'Omnichannel and Multilingual',
    description:
      'AI models support 40+ languages and integrate seamlessly with any text-based communication channel.',
  },
  {
    id: 4,
    icon: <Icon5 />,
    title: 'Patent',
    description: (
      <>
        Patented AI message generation architecture that ensure faster, highly accurate responses
        with the recognizes by{' '}
        <a target="_blank" href={PATENT_LINK} rel="noreferrer">
          USPTO patented technology
        </a>
        .
      </>
    ),
  },
  {
    id: 5,
    icon: <Icon6 />,
    title: 'Security',
    description:
      'Data encryption and anonymization, dedicated servers and compliance with regulations ensure your sensitive business data is safe and secure.',
  },
  {
    id: 6,
    icon: <Icon7 />,
    title: 'Unlimited Users',
    description:
      'Scale your customer support operations effortlessly, with unlimited access for every team member—no restrictions, no additional fees.',
  },
  {
    id: 7,
    icon: <Icon8 />,
    title: 'Pricing',
    description:
      'Transparent Pricing with No Hidden Fees. Enjoy peace of mind with our straightforward, transparent pricing model. Predictable costs with no hidden fees.',
  },
];

export const SuperChargeYourBusiness = () => {
  const isMdUp = useMediaQuery('(min-width: 768px)');
  return (
    <div className="relative bg-white">
      <Container className="md:py-18-vw py-10">
        <section className="flex flex-col md:gap-10-vw gap-4">
          <div className="w-full flex-col flex justify-start md:gap-2.5-vw gap-2">
            <Typography
              $type="Heading2Big"
              as="h2"
              $fontSizeXs="25px"
              $lineHeight={{ xs: '150%' }}
              className="font-formula"
            >
              SuperCharge Your Business 360°
            </Typography>
            <Typography $type="Heading4" className="font-jamjuree md:max-w-[59.444vw]">
              CoSupport AI offers an unmatched combination of advanced features, cost savings, and
              scalability, making them the go-to solutions for modern customer support.
            </Typography>
          </div>
          {isMdUp ? (
            <div className="flex md:gap-6-vw gap-2 flex-wrap">
              {CARDS_CONTENT.map((cardData) => {
                return (
                  <Card
                    data={cardData}
                    key={cardData.id}
                    className="lg:flex-[1_0_21%] md:flex-[1_0_33.33%] md:pb-15-vw"
                  />
                );
              })}
            </div>
          ) : (
            <MobileSlider
              className="!mb-0"
              carouselWrapperClassName="pb-3 w-[calc(100%+2rem)] -ml-4"
              slides={CARDS_CONTENT.map((cardData) => {
                return {
                  id: cardData.id + 1,
                  content: (
                    <Card
                      data={cardData}
                      className="lg:flex-[1_0_21%] md:flex-[1_0_33.33%] md:pb-15-vw h-full"
                    />
                  ),
                };
              })}
            />
          )}
        </section>
      </Container>
    </div>
  );
};
