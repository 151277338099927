import React, { ReactElement } from 'react';
import { Typography } from '@/components/Typography/Typography';
import { AccordionComponent } from '@/components/AccordionComponent/AccordionComponent';

type Props = {
  handleItemsChange: (value: string | number) => void;
};

const accordionItems: {
  id: string | number;
  triggerTitle: string;
  content: ReactElement | string;
  img?: string;
}[] = [
  {
    id: '0',
    triggerTitle: '90% Resolution Rate:',
    img: '/img/main-page/redefine-accordion/slide-1.webp',
    content: <>Handle up to 90% of support inquiries autonomously with unmatched accuracy.</>,
  },
  {
    id: '1',
    triggerTitle: '99% Response Accuracy:',
    img: '/img/main-page/redefine-accordion/slide-2.webp',
    content: (
      <>
        Ensure that every answer is precise, using only your company’s data for reliable, human-like
        interactions.
      </>
    ),
  },
  {
    id: '2',
    triggerTitle: '80% Reduction in Ticket Processing Time:',
    img: '/img/main-page/redefine-accordion/slide-3.webp',
    content:
      'Boost agent productivity and cut response times by up to 80%, empowering teams to focus on high-value tasks.',
  },
];

export const AccordionPart = ({ handleItemsChange }: Props) => {
  return (
    <div className="flex flex-col items-center justify-center md:gap-6-vw gap-5 z-10">
      <div className="md:max-w-[36.528vw] flex flex-col h-full md:gap-8-vw gap-8 lg:py-14-vw">
        <div className="flex flex-col justify-center gap-2.5">
          <Typography as="h2" $weight="700" $type="Heading2" className="font-formula">
            Redefine customer support efficiency and productivity
          </Typography>
          <Typography $weight="400" $type="PMedium" className="font-jamjuree">
            CoSupport AI provides a fully autonomous AI solution for customer support, designed for
            companies handling large volumes of daily customer interactions.
          </Typography>
        </div>
        <div>
          <AccordionComponent
            triggerType={'PMedium'}
            imageFit
            accordionItems={accordionItems}
            handleItemsChange={handleItemsChange}
          />
        </div>
      </div>
    </div>
  );
};
