import React, { ReactElement } from 'react';
import { Drawer } from 'vaul';
import { CloseIconSimple } from '@/assets/icons/closeIconSimple';
import { clsx } from 'clsx';

interface DrawerProps {
  drawerTrigger?: ReactElement;
  drawerContent?: ReactElement;
  triggerClassName?: string;
  contentClassName?: string;
  drawerTitle?: string;
  darkMode?: boolean;
}

export const DrawerComponent = ({
  drawerTrigger,
  drawerContent,
  triggerClassName,
  contentClassName,
  drawerTitle = 'Menu',
  darkMode = false,
}: DrawerProps) => {
  return (
    <Drawer.Root direction="right">
      <Drawer.Trigger asChild className={triggerClassName}>
        {drawerTrigger}
      </Drawer.Trigger>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-black/40 backdrop-blur-sm" />
        <Drawer.Content
          className={clsx(
            contentClassName,
            'flex flex-col rounded-t-[10px] h-full w-5/6 mt-24 fixed bottom-0 right-0',
            darkMode ? 'bg-ebony' : 'bg-light'
          )}
        >
          <Drawer.Title hidden>{drawerTitle}</Drawer.Title>
          <div
            className={clsx(
              darkMode ? 'bg-ebony' : 'bg-light',
              'p-4 flex-1 h-full gap-12 flex flex-col overflow-auto'
            )}
          >
            <div className="flex items-center justify-between">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="20"
                viewBox="0 0 33 20"
                fill="none"
              >
                <path
                  d="M10.0783 0.000699656C7.42249 0.000699746 4.81087 1.05423 2.93294 2.92953C1.05501 4.80483 -4.85198e-07 7.34828 -5.41967e-07 10.0003C-5.98735e-07 12.6524 1.05501 15.1959 2.93294 17.0712C4.81087 18.9465 7.42249 20 10.0783 20L10.0783 10.0003L10.0783 0.000699656Z"
                  fill={darkMode ? 'white' : '#05050D'}
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M22.2471 19.9993C27.7775 19.9993 32.2607 15.5223 32.2607 9.99965C32.2607 4.477 27.7775 1.55826e-07 22.2471 1.00385e-07C16.7167 4.49437e-08 12.2334 4.477 12.2334 9.99965C12.2334 15.5223 16.7167 19.9993 22.2471 19.9993ZM22.2491 14.104C24.514 14.104 26.35 12.2706 26.35 10.0089C26.35 7.74729 24.514 5.91386 22.2491 5.91386C19.9843 5.91386 18.1483 7.74729 18.1483 10.0089C18.1483 12.2706 19.9843 14.104 22.2491 14.104Z"
                  fill={darkMode ? 'white' : '#05050D'}
                />
              </svg>
              <Drawer.Close>
                <CloseIconSimple strokeColor={darkMode ? 'white' : 'black'} />
              </Drawer.Close>
            </div>
            {drawerContent}
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};
