import React from 'react';
import { AwardsComponent } from '@/components/AwardsComponent/AwardsComponent';
import { PatentSolution } from '@/components/PatentSolution/PatentSolution';
import { clsx } from 'clsx';
import Image from 'next/image';

export default function AwardsSection({ className = '' }) {
  return (
    <div className={clsx(className, 'flex lg:flex-col lg:w-full flex-wrap gap-[27px]')}>
      <div className="flex flex-wrap items-center md:gap-5 gap-4  h-auto">
        <Image
          src="/img/ai-world-series.webp"
          className="max-w-[250px] max-h-[33px] w-auto"
          alt="awards-icon"
          width="726"
          height="108"
        />
        <Image
          src="/img/patent-ai-solution-icon.webp"
          className="max-w-[250px] max-h-[33px] w-auto"
          alt="awards-icon"
          width="726"
          height="108"
        />
      </div>
      <div className="flex max-md:flex-wrap items-center gap-2.5">
        <Image
          src="/img/footer-iso-icon-light.webp"
          className="max-md:min-w-[120px] max-w-[120px] max-h-[58px] w-auto"
          width="261"
          height="128"
          alt="iso-icon"
        />
        <Image
          src="/img/footer-gdpr-icon-light.webp"
          className="max-md:min-w-[120px] max-w-[120px] max-h-[58px] w-auto"
          width="261"
          height="128"
          alt="gdpr-icon"
        />
      </div>
    </div>
  );
}
