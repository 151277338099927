import React from 'react';
import { IconComponent } from './IconComponent';
import { Typography } from '@/components/Typography/Typography';
import { Icon1, Icon2, Icon3, Icon4, Icon5, Icon6, Icon7, Icon8 } from './icons-collection';
import { PATH_INTEGRATION } from '@/constants/spa-routes';

const icons = [
  {
    id: 0,
    icon: <Icon1 />,
    helpText: 'Zendesk',
    link: PATH_INTEGRATION.INTEGRATION_ZENDESK,
  },
  {
    id: 1,
    icon: <Icon2 />,
    helpText: 'Slack',
    link: '',
  },
  {
    id: 2,
    icon: <Icon3 />,
    helpText: 'Freshdesk',
    link: PATH_INTEGRATION.INTEGRATION_FRESHDESK,
  },
  {
    id: 3,
    icon: <Icon4 />,
    helpText: 'Microsoft Teams',
    link: '',
  },
  {
    id: 4,
    icon: <Icon5 />,
    helpText: 'Intercom',
    link: '',
  },
  {
    id: 5,
    icon: <Icon6 />,
    helpText: 'Shopify',
    link: '',
  },
  {
    id: 6,
    icon: <Icon7 />,
    helpText: 'ZohoDesk',
    link: '',
  },
  {
    id: 7,
    icon: <Icon8 />,
    helpText: 'Stripe',
    link: '',
  },
];

export const IconsPart = () => {
  return (
    <div className="flex flex-col md:py-10 items-center justify-center md:gap-6-vw gap-5 md:w-1/2 z-10 pb-5-vw">
      <div className="flex justify-center items-center">
        <div className="relative md:max-w-[75%]">
          <Typography $type="Heading1" className="font-formula" $weight="700">
            Ready-to-go Integrations
          </Typography>
          <Typography $type="PMedium" className="opacity-70 font-jamjuree">
            CoSupport AI seamlessly integrates with the tools and platforms you already use,
            ensuring a smooth transition to AI-driven customer support without disrupting your
            workflow.
          </Typography>
        </div>
      </div>
      <div className="w-full md:max-w-[75%] flex justify-start">
        <div className="flex flex-wrap justify-start md:gap-3.5-vw gap-3.5">
          {icons.map((icon) => {
            return <IconComponent key={icon.id} icon={icon} />;
          })}
        </div>
      </div>
      <div className="md:max-w-[75%] max-md:hidden">
        <Typography $type="PSmall" className="opacity-40 font-jamjuree">
          * Other help desks and third-party platforms can be integrated via API. <br />{' '}
          **Integration with in-house systems and back-end infrastructure available as well.
        </Typography>
      </div>
    </div>
  );
};
