import Image from 'next/image';
import React from 'react';

export const REVIEWS_ITEMS = [
  {
    id: 0,
    name: 'Ann Kuss',
    title: (
      <>
        CEO,
        <br />
        Outstaff Your Team
      </>
    ),
    logo: (
      <div className="md:w-[7.639vw] md:h-[3.472vw] w-[100px] h-[45px]">
        <Image
          width="0"
          height="0"
          sizes="100vw"
          className="w-full h-full object-contain"
          alt="Outstaff Your Team logo"
          src="/img/main-page/reviews/outstaff-your-team.webp"
        />
      </div>
    ),
    description:
      "CoSupport AI was set up in just three days, seamlessly integrating with our CRM and decreasing resolution time by 50%. The transparent pricing made budgeting easy, and the generative AI for customer support improved our workflow without needing extra training. We're thrilled with the efficiency boost.",
    img: '/img/main-page/reviews/kuss.webp',
  },
  {
    id: 1,
    title: (
      <>
        CEO,
        <br />
        Label Your Data
      </>
    ),
    name: 'Karyna Naminas',
    logo: (
      <div className="md:w-[6.736vw] md:h-[3.472vw] w-[87px] h-[45px]">
        <Image
          width="0"
          height="0"
          sizes="100vw"
          className="w-full h-full object-contain"
          alt="Outstaff Your Team logo"
          src="/img/main-page/reviews/label-your-data.webp"
        />
      </div>
    ),
    description:
      'CoSupport AI transformed our decision-making by giving non-technical team members easy access to data insights through Slack. This AI for customer support is simple to use, integrates quickly, and delivers faster analytics across departments. We’ve seen significant improvements in team performance and efficiency, all with transparent pricing that fits our budget.',
    img: '/img/main-page/reviews/naminas.webp',
  },
  {
    id: 2,
    title: (
      <>
        CEO,
        <br />
        Quidget
      </>
    ),
    name: 'Eugene Gordienko',
    logo: (
      <div className="md:w-[7.986vw] md:h-[1.597vw] w-[105px] h-[21px]">
        <Image
          width="0"
          height="0"
          sizes="100vw"
          className="w-full h-full object-contain"
          alt="Outstaff Your Team logo"
          src="/img/main-page/reviews/quidget.webp"
        />
      </div>
    ),
    description:
      'Our partnership with CoSupport AI has been a game-changer. CoSupport Customer improved our resolution rate by 32%, and their generative AI for customer support solutions have automated routine tasks for both our team and clients. The seamless integration, high accuracy, and ease of use have improved efficiency across the board. CoSupport AI has helped us expand our market offerings and boost customer satisfaction.',
    img: '/img/main-page/reviews/gordienko.webp',
  },
  {
    id: 3,
    title: (
      <>
        CEO,
        <br />
        Softorino
      </>
    ),
    name: 'Josh Brown',
    logo: (
      <div className="md:w-[4.375vw] md:h-[3.472vw] w-[56px] h-[45px]">
        <Image
          width="0"
          height="0"
          sizes="100vw"
          className="w-full h-full object-contain"
          alt="Outstaff Your Team logo"
          src="/img/main-page/reviews/softorino.webp"
        />
      </div>
    ),
    description:
      'CoSupport AI was easy to set up with our Zendesk system — this AI for customer support learned everything on its own with just an API key. It improved our team’s performance without needing any manual setup or training. We’d love to see built-in dashboards for better reporting, but overall, the tool is super easy to use and a great value for the cost.',
    img: '/img/main-page/reviews/brown.webp',
  },
  {
    id: 4,
    title: (
      <>
        Account Manager,
        <br />
        SupportYourApp
      </>
    ),
    name: 'Axel Barrionuevo',
    logo: (
      <div className="md:w-[7.569vw] md:h-[3.472vw] w-[98px] h-[45px]">
        <Image
          width="0"
          height="0"
          sizes="100vw"
          className="w-full h-full object-contain"
          alt="Outstaff Your Team logo"
          src="/img/main-page/reviews/support-your-app.webp"
        />
      </div>
    ),
    description:
      'CoSupport AI automates 250 daily responses, increasing the resolution rate by 45%. It delivers accurate, relevant answers that improve with agent feedback. Integration was fast, and the AI for customer support has helped reduce costs while increasing customer satisfaction. The team’s expertise and responsiveness exceeded expectations, making the overall experience highly positive.',
    img: '/img/main-page/reviews/alex.webp',
  },
  {
    id: 5,
    title: (
      <>
        Project Manager,
        <br />
        Projectfitter
      </>
    ),
    name: 'Yaro Burgman',
    logo: (
      <div className="md:w-[9.167vw] md:h-[1.458vw] w-[113px] h-[17px]">
        <Image
          width="0"
          height="0"
          sizes="100vw"
          className="w-full h-full object-contain"
          alt="Outstaff Your Team logo"
          src="/img/main-page/reviews/project-fitter.webp"
        />
      </div>
    ),
    description:
      'CoSupport AI streamlined our support operations, cutting response times by 40% and automating a substantial volume of routine inquiries. This enhanced efficiency enabled us to prioritize precision hiring, achieving a 35% faster time-to-hire while significantly reducing support costs.',
    img: '/img/main-page/reviews/yaro.webp',
  },
];
