import React, { forwardRef } from 'react';

type IconProps = {
  className?: string;
};
export const MobileMenuIcon = forwardRef(
  ({ className = 'header-menu-icon', ...rest }: IconProps, ref) => {
    return (
      <svg
        {...rest}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <circle cx="2" cy="2" r="2" fill="white" />
        <circle cx="2" cy="10" r="2" fill="white" />
        <circle cx="2" cy="18" r="2" fill="white" />
        <circle cx="10" cy="2" r="2" fill="white" />
        <circle cx="10" cy="10" r="2" fill="white" />
        <circle cx="10" cy="18" r="2" fill="white" />
        <circle cx="18" cy="2" r="2" fill="white" />
        <circle cx="18" cy="10" r="2" fill="white" />
        <circle cx="18" cy="18" r="2" fill="white" />
      </svg>
    );
  }
);
