import React from 'react';

export const FAQItemIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="43" height="44" viewBox="0 0 43 44" fill="none">
      <circle cx="21.4977" cy="22" r="15" transform="rotate(135 21.4977 22)" fill="white" />
      <path
        d="M21.5703 28.0762L21.5703 21.5823L21.5703 15.5978"
        stroke="#05050D"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M15.332 21.8369L21.8259 21.8369L27.8104 21.8369"
        stroke="#05050D"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
};
