import React, { useEffect, useRef, useState } from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';
import { CloseIconSimple } from '@/assets/icons/closeIconSimple';
import { clsx } from 'clsx';
import styled from 'styled-components';
import { PATH_DEMO } from '@/constants/spa-routes';
import Link from 'next/link';

const StyledWrapper = styled.div({
  cursor: 'pointer',
  'a:hover:not(:hover #close-icon-simple) p': {
    opacity: 1,
    '#banner-span-green': {
      color: COLORS.EBONY,
    },
  },
});

type HeaderBannerProps = {
  disableClose?: boolean;
};

export const HeaderBanner = ({ disableClose = false }: HeaderBannerProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [showBanner, setShowBanner] = useState(true);
  const [height, setHeight] = useState(0);

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(false);
    sessionStorage.setItem('header-banner-closed', 'true');
    document.body.classList.remove('header-banner', 'bg-ebony');
  };

  const divRef = useRef<HTMLDivElement | null>(null);

  const observerRef = useRef<ResizeObserver>();

  useEffect(() => {
    if (divRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        setHeight(divRef?.current?.offsetHeight ? divRef?.current?.offsetHeight : 0);
      });
      resizeObserver.observe(divRef.current);
      observerRef.current = resizeObserver;
    }
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [divRef.current]);

  useEffect(() => {
    setShowBanner(
      Boolean(typeof window !== 'undefined' && sessionStorage.getItem('header-banner-closed'))
    );

    return () => {
      document.body.classList.remove('header-banner', 'bg-ebony');
    };
  }, []);
  useEffect(() => {
    if (!showBanner && !disableClose) {
      document.body.classList.add('bg-ebony');
      document.body.style.setProperty('margin-top', `${height}px`);
    } else {
      document.body.classList.remove('header-banner');
      document.body.style.removeProperty('margin-top');
    }
  }, [showBanner, height]);

  return isOpen && (disableClose || !showBanner) ? (
    <div ref={divRef} className="h-full w-full">
      <Container
        id="banner"
        className={clsx(
          'bg-dark-green hover:bg-[radial-gradient(50%_1714.29%_at_50%_50%,#9AFF4A_0%,#26F966_71.4%,#38E99F_96.4%)] min-h-10 relative overflow-hidden [&_svg_line]:hover:stroke-ebony [&_span]:hover:!text-ebony flex items-center justify-center'
        )}
      >
        <StyledWrapper
          className={clsx(
            'relative w-full flex items-center justify-center',
            disableClose ? 'max-md:pr-20' : 'max-md:pr-8'
          )}
        >
          <Link
            prefetch={false}
            href={PATH_DEMO.CUSTOMER_DEMO}
            className="relative w-full flex items-center justify-center !no-underline"
          >
            <div className="relative flex flex-row-reverse gap-3 items-center">
              {disableClose ? (
                <div className="flex flex-col items-center md:py-3 py-2.5">
                  <Typography
                    $weight="600"
                    $type="PMicro"
                    $fontSizeSm="0.563rem"
                    $fontSizeXs="0.563rem"
                    $color={COLORS.LIGHT}
                    className="font-formula md:text-center text-center max-md:flex max-md:flex-col max-md:items-center"
                  >
                    <span>
                      <span className="opacity-80 font-normal">🚀🚀🚀 Try an </span>
                      <span id="banner-span-green" className="italic underline text-green-default">
                        AI-Powered Chatbot
                      </span>{' '}
                      <span className="opacity-80 font-normal md:hidden">🚀🚀🚀</span>
                    </span>{' '}
                    <span className="opacity-80 font-normal">
                      That Resolves Customer Inquiries 3x Faster{' '}
                      <span className="max-md:hidden">🚀🚀🚀</span>
                    </span>
                  </Typography>
                </div>
              ) : (
                <Typography
                  $weight="700"
                  $type="PMicro"
                  $fontSizeSm="0.688rem"
                  $color={COLORS.LIGHT}
                  className="font-formula md:py-3 py-2.5 md:text-center max-md:flex max-md:flex-col max-md:items-center"
                >
                  <span>
                    <span className="opacity-80 font-normal">🚀🚀🚀 Try an </span>
                    <span id="banner-span-green" className="italic underline text-green-default">
                      AI-Powered Chatbot
                    </span>{' '}
                    <span className="opacity-80 font-normal md:hidden">🚀🚀🚀</span>
                  </span>{' '}
                  <span className="opacity-80 font-normal">
                    That Resolves Customer Inquiries 3x Faster{' '}
                    <span className="max-md:hidden">🚀🚀🚀</span>
                  </span>
                </Typography>
              )}
            </div>
          </Link>
          {!disableClose && (
            <div className="absolute right-0 h-full flex justify-center items-center cursor-default">
              <CloseIconSimple
                onClick={handleClose}
                strokeColor={COLORS.LIGHT}
                className="opacity-50"
              />
            </div>
          )}
        </StyledWrapper>
      </Container>
    </div>
  ) : null;
};
