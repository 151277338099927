import React from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';

export const PatentBlock = () => {
  return (
    <Container className="justify-center flex">
      <div className="flex flex-col md:w-8/12 md:gap-3.5-vw gap-2">
        <div className="flex flex-col md:gap-2.5-vw gap-2">
          <Typography
            as="h3"
            $type="Heading1"
            $weight="700"
            className="md:text-center text-start font-formula"
          >
            Patented AI Solution for Customer Support
          </Typography>
          <Typography
            $type="Heading4"
            $weight="400"
            className="md:text-center text-start opacity-70 font-jamjuree"
          >
            CoSupport AI's patented message generation architecture is a groundbreaking advancement
            in AI-driven customer support, delivering unmatched speed, precision, and cost
            efficiency.
          </Typography>
        </div>
        <div className="">
          <picture className="w-full h-full object-contain">
            <source srcSet="/img/main-page/patent-img-desk.webp" media="(min-width: 640px)" />
            <img
              className="h-full w-full object-contain object-bottom"
              src="/img/main-page/patent-img-mob.webp"
              alt={`patent image`}
            />
          </picture>
        </div>
      </div>
    </Container>
  );
};
