import React, { useEffect, useRef, useState } from 'react';
import { useAnimate } from 'framer-motion';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { clsx } from 'clsx';

const IMAGES_URLS = [
  { id: 0, url: 'img/main-page/world-series.webp' },
  { id: 1, url: 'img/main-page/captera.webp' },
  { id: 2, url: 'img/main-page/gdpr.webp' },
  { id: 3, url: 'img/main-page/crozdesk.webp' },
  { id: 4, url: 'img/main-page/iso.webp' },
  { id: 5, url: 'img/main-page/top-performer.webp' },
  { id: 6, url: 'img/main-page/patent.webp' },
];

export const ImagesPart = () => {
  const [isHoverImage, setIsHoverImage] = useState(false);
  const [scope, animate] = useAnimate();
  const isLgUp = useMediaQuery('(min-width: 1024px)');
  const scrollDivRef = useRef<HTMLDivElement | null>(null);

  const animationRef = useRef<any>(null);

  const handleHoverImage = () => {
    setIsHoverImage(true);
  };
  const handleLeaveImage = () => {
    setIsHoverImage(false);
  };
  const handleTouchImage = () => {
    setIsHoverImage((prevState) => !prevState);
  };

  useEffect(() => {
    if (scrollDivRef.current) {
      animationRef.current = animate(
        scrollDivRef.current,
        { x: ['0%', '-50%'] },
        {
          ease: 'linear',
          duration: 20,
          repeat: Infinity,
        }
      );
    }

    return () => {
      if (animationRef.current) {
        animationRef.current.stop();
      }
    };
  }, [isLgUp, animate]);

  useEffect(() => {
    if (animationRef.current) {
      if (isHoverImage) {
        animationRef.current.pause();
      } else {
        animationRef.current.play();
      }
    }
  }, [isHoverImage]);

  return (
    <div className="lg:mb-10-vw mb-4">
      <div className="overflow-hidden">
        <div className="flex" ref={scope}>
          {isLgUp && (
            <div className="flex gap-6 w-full justify-center">
              {IMAGES_URLS.map((image, index) => (
                <div key={image.id} className="flex items-center">
                  <img
                    alt={`logo-img-${image.url}`}
                    src={image.url}
                    className={clsx(
                      image.id === 5 && 'min-w-[4.444vw] w-[4.444vw]',
                      'object-contain grayscale hover:grayscale-0 transition-all duration-300 hover:opacity-100 opacity-50'
                    )}
                  />
                </div>
              ))}
            </div>
          )}
          {!isLgUp && (
            <div className="flex" ref={scrollDivRef}>
              <div className="flex gap-6 mr-6 w-[1800px]">
                {[...IMAGES_URLS, ...IMAGES_URLS].map((image, index) => (
                  <div key={`${index}-${image.url}`} className="flex items-center">
                    <img
                      alt={`logo-img-${image.url}`}
                      onTouchStart={handleTouchImage}
                      onMouseEnter={handleHoverImage}
                      onMouseLeave={handleLeaveImage}
                      src={image.url}
                      className={clsx(
                        image.id === 5 && 'min-w-16 w-16',
                        'object-contain grayscale hover:grayscale-0 transition-all duration-300 hover:opacity-100 opacity-50'
                      )}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
