import React, { ReactElement } from 'react';
import Link from 'next/link';
import { PATH_AUTHORS, PATH_TAGS } from '@/constants/spa-routes';
import { EyeIcon } from '@/assets/icons/EyeIcon';
import { clsx } from 'clsx';
import Image from 'next/image';

type ArticleProps = {
  id: number;
  attributes: {
    Hero_Image: {
      data: {
        attributes: {
          url: string;
          alternativeText: string;
        };
      };
    };
    author: {
      data: {
        id: number;
        attributes: {
          Name: string;
          Position: string;
          Avatar: {
            data: {
              attributes: {
                url: string;
              };
            };
          };
        };
      };
    };
    URL: string;
    Title: string;
    Short_Description: string;
    publishedAt: string;
    views: number;
    tags: {
      data: [
        {
          id: number;
          attributes: {
            Name: string;
          };
        },
      ];
    };
  };
};
function PublishDate({ publishDate }: { publishDate: ArticleProps['attributes']['publishedAt'] }) {
  const formattedDate = publishDate
    ? new Date(publishDate).toLocaleString('en', {
        month: 'long',
        day: '2-digit',
        year: 'numeric',
      })
    : null;

  if (!formattedDate) {
    return null;
  }
  return <span className="published-date">{formattedDate}</span>;
}

function AuthorInfo({ author }: { author: ArticleProps['attributes']['author'] }) {
  if (!author?.data) {
    return null;
  }

  const authorUrl = author.data.attributes.Name.toLowerCase().split(' ').join('-');

  return (
    <div className="article-author">
      <div className="article-author-avatar">
        <Link href={`${PATH_AUTHORS.ROOT}/${authorUrl}`} prefetch={false}>
          <Image
            src={author?.data?.attributes?.Avatar?.data?.attributes?.url}
            width={45}
            height={45}
            quality={100}
            style={{ borderRadius: '50%', width: '60px', height: 'auto' }}
            alt={author?.data?.attributes?.Name || ''}
          />
        </Link>
      </div>
      <div className="article-author-name">
        <p>
          by{' '}
          <Link
            href={`${PATH_AUTHORS.ROOT}/${authorUrl}`}
            className="hover:underline"
            prefetch={false}
          >
            {author.data.attributes.Name}
          </Link>
        </p>
        <p>
          <Link
            href={`${PATH_AUTHORS.ROOT}/${authorUrl}`}
            className="!no-underline"
            prefetch={false}
          >
            <span className="grey">{author.data.attributes.Position}</span>
          </Link>
        </p>
      </div>
    </div>
  );
}

export default function Article({
  article,
  className,
  authorHidden = false,
}: {
  article: ArticleProps;
  className?: string;
  authorHidden?: boolean;
}): ReactElement {
  return (
    <div className={clsx(className, 'article next')}>
      <div className="image-block cursor-pointer relative">
        <Link href={`${article.attributes.URL}`} prefetch={false}>
          <Image
            src={article.attributes?.Hero_Image?.data?.attributes?.url}
            alt={article.attributes?.Hero_Image?.data?.attributes?.alternativeText || ''}
            width={400}
            height={0}
            quality={100}
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </Link>
        <div className="absolute w-full flex flex-row justify-between items-center bottom-0 py-2.5 px-4 bg-ebony_opacity_50">
          <div
            className="flex items-center gap-2 truncate views-counter-value"
            title={`${article?.attributes?.views || '0'} views`}
          >
            <EyeIcon className="min-w-5.5 eye-icon" />
            <span className="font-jamjuree truncate text-white font-semibold text-sm">
              {article?.attributes?.views || '0'}
            </span>
          </div>
          <PublishDate publishDate={article.attributes.publishedAt} />
        </div>
      </div>
      <div className="wrapper-block">
        <div className="title-block">
          {!authorHidden && <AuthorInfo author={article.attributes.author} />}
          <Link href={`${article.attributes.URL}`} prefetch={false}>
            <h6>{article.attributes.Title}</h6>
          </Link>
        </div>
        <div className="content-block">
          <div>
            <Link href={`${article.attributes.URL}`} prefetch={false}>
              <p>{article.attributes.Short_Description}</p>
            </Link>
          </div>
          <div className="content-tags">
            {article.attributes.tags.data.map((tag) => {
              const url = tag.attributes.Name.toLowerCase().trim().replace(/\ /g, '-');
              return (
                <Link
                  prefetch={false}
                  key={`${article.id}-tag-${tag.id}`}
                  href={`${PATH_TAGS.ROOT}/${url}`}
                  className={`hover:[&>div]:bg-[#d8d8d8] [&>div]:transition-all duration-200`}
                >
                  <div className="tag">{tag.attributes.Name}</div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
