import React, { useEffect, useState } from 'react';

const wait = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const useLoader = (delay = 2000) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const hideLoader = () => {
      setLoading(false);
    };

    (async () => {
      window.addEventListener('load', hideLoader);
      await wait(delay);
      hideLoader();
    })();

    return () => {
      window.removeEventListener('load', hideLoader);
    };
  }, []);

  return loading;
};
