export const AgentAiAssistant = ({ height = '25', width = '25', className = '' }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="25" rx="4" fill="#75F3FB" />
      <path
        d="M17.7418 14.8L17.7418 9.74192C17.7418 7.12303 15.6188 5 12.9999 5V5C10.381 5 8.25798 7.12303 8.25798 9.74192L8.25797 16.1682C8.25797 16.959 8.89902 17.6001 9.6898 17.6001L11.5567 17.6001"
        stroke="#05050D"
        strokeWidth="1.5"
      />
      <path
        d="M7.76184 10.1864C7.04845 10.3021 6.50378 10.9209 6.50378 11.667L6.50378 12.8004C6.50378 13.5464 7.04845 14.1653 7.76184 14.2809L7.76184 10.1864Z"
        fill="#05050D"
        stroke="#05050D"
      />
      <path
        d="M18.2418 14.2814C18.9552 14.1657 19.4999 13.5468 19.4999 12.8008L19.4999 11.6674C19.4999 10.9214 18.9552 10.3025 18.2418 10.1868L18.2418 14.2814Z"
        fill="#05050D"
        stroke="#05050D"
      />
      <path
        d="M11.4698 17.6C11.4698 18.1126 11.8678 18.5 12.3246 18.5C12.7814 18.5 13.1794 18.1126 13.1794 17.6C13.1794 17.0873 12.7814 16.7 12.3246 16.7C11.8678 16.7 11.4698 17.0873 11.4698 17.6Z"
        fill="#05050D"
        stroke="#05050D"
      />
    </svg>
  );
};
