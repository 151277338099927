import React from 'react';
import { motion } from 'framer-motion';
import Image from 'next/image';

type Props = {
  selectedFilter: number;
};

const images = [
  '/img/main-page/redefine-accordion/slide-1.webp',
  '/img/main-page/redefine-accordion/slide-2.webp',
  '/img/main-page/redefine-accordion/slide-3.webp',
];
export const ImagePart = ({ selectedFilter }: Props) => {
  return (
    <div className="image-container md:max-w-[45.694vw] w-full flex-grow md:p-[30px]">
      <div className="h-full w-full md:rounded-xl relative max-md:min-h-[420px] min-h-[45.583vw]">
        {images.map((image, index) => (
          <div
            key={image}
            className="wrapper h-full w-auto absolute md:rounded-md-10-vw rounded-md-10"
          >
            <motion.div
              className="h-full"
              animate={{
                opacity: selectedFilter === index ? 1 : 0,
              }}
              transition={{ duration: 0.6 }}
            >
              <Image
                width="0"
                height="0"
                sizes="100vw"
                className="w-full h-full object-contain"
                alt="Faster, more precise, cost-efficient, and secure solution for Customer Support Image"
                src={image}
              />
            </motion.div>
          </div>
        ))}
      </div>
    </div>
  );
};
