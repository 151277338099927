import React, { useState } from 'react';
import { PlayButtonIcon } from '@/assets/icons/playButtonIcon';
import Image from 'next/image';
import { clsx } from 'clsx';

type PlayButtonProps = {
  onClick?: () => void;
  poster?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

export const PlayButton = ({
  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  poster,
}: PlayButtonProps) => {
  const [showBanner, setShowBanner] = useState(true);

  const onHoverHandler = () => {
    setShowBanner(false);
    onMouseEnter();
  };
  const onOutHandler = () => {
    setShowBanner(true);
    onMouseLeave();
  };
  return (
    <div onMouseLeave={onOutHandler} onMouseEnter={onHoverHandler} onClick={onClick}>
      <div className="absolute flex items-center justify-center flex-shrink h-full cursor-pointer pointer-events-auto w-full z-[100] [&_.circle]:hover:md:p-5-vw [&_.circle]:hover:p-3 [&_.circle:first-child]:hover:bg-[#BEEEF21A] [&_.circle:second-child]:hover:bg-[#BEEEF233]">
        <div className="absolute w-full h-full bg-ebony bg-opacity-30 z-50"></div>
        <div className="circle rounded-full overflow-hidden backdrop-blur-sm duration-300 z-50">
          <div className="circle rounded-full overflow-hidden backdrop-blur-sm duration-300">
            <PlayButtonIcon className="md:w-[6.944vw] md:h-[6.944vw] w-[52px] h-[52px]" />
          </div>
        </div>
      </div>
      {poster && (
        <div className="absolute w-full h-full">
          <div className="absolute w-full h-full bg-ebony bg-opacity-30 z-50" />
          <Image
            width="0"
            height="0"
            sizes="100vw"
            alt={`${poster} video poster Image`}
            src={poster}
            className={clsx(
              'w-full h-full object-cover z-0 transition-opacity duration-300',
              showBanner ? 'opacity-100' : 'opacity-0'
            )}
          />
        </div>
      )}
    </div>
  );
};
