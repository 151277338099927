import React from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';
import { BackgroundAnimation } from './BackgroundAnimation';
import { GreenButton } from '@/components/ButtonsCollection/GreenButton/GreenButton';
import { PATH_DEMO, PRODUCT_DEMO } from '@/constants/spa-routes';
import { BlackButton } from '@/components/ButtonsCollection/BlackButton/BlackButton';
import { SmallArrowIcon } from '@/assets/icons/smallArrowIcon';
import { Tags } from '@/components/PagesSections/MainPageSections/FirstSection/Content/Tags';

export const LastSection = () => {
  return (
    <div className="bg-light relative overflow-hidden">
      <BackgroundAnimation />
      <Container className="max-lg:backdrop-blur-[150px] md:py-24.5-vw pb-10 pt-[70px] flex justify-center">
        <div className="flex flex-col md:gap-8-vw gap-8">
          <div className="flex flex-col items-center md:gap-5-vw gap-2 z-50">
            <Typography
              as="h3"
              $weight="700"
              $type="Heading1"
              className="font-formula text-center md:max-w-[59.444vw]"
            >
              Try the All-in-One Generative AI solution for Customer Support with Unlimited User
              Access
            </Typography>
            <Typography
              $weight="500"
              $type="Heading3Big"
              $fontSizeXs="16px"
              className="font-jamjuree text-center max-md:!font-normal"
            >
              From customer-centric support interactions to a customized tone of voice.
            </Typography>
          </div>
          <div className="flex flex-col md:gap-10-vw gap-6 items-center z-50">
            <div className="flex max-md:w-full flex-wrap md:gap-5-vw gap-4 justify-center">
              <GreenButton
                linkBtnClassName="max-md:w-full"
                url={PRODUCT_DEMO.ROOT}
                className="whitespace-nowrap max-md:w-auto max-md:flex-grow"
              >
                <Typography $type="Heading4" $weight="700">
                  See in Action
                </Typography>
              </GreenButton>
              <BlackButton
                url={PATH_DEMO.ROOT}
                className="whitespace-nowrap max-md:w-auto max-md:flex-grow"
                linkBtnClassName="max-md:w-full !px-11-vw"
              >
                <Typography $color={COLORS.WHITE} $type="Heading4" $weight="700">
                  Request Demo
                </Typography>
                <SmallArrowIcon />
              </BlackButton>
            </div>
          </div>
          <div className="flex justify-center">
            <Tags className="justify-center" color={COLORS.EBONY} />
          </div>
        </div>
      </Container>
    </div>
  );
};
