export const COSSUPPURT_AI_VIDEO = 'https://www.youtube.com/watch?v=FxKErwBIYng';

export const COSSUPPURT_AI_VIDEO_IFRAME =
  'https://www.youtube.com/embed/FxKErwBIYng?si=qT18_z6nK4SVEen9';

export const MAIN_PAGE_VIDEO_LINk =
  'https://www.youtube.com/watch?v=FxKErwBIYng&ab_channel=CoSupportAI';

export const PATENT_LINK = 'https://patents.google.com/patent/US11823031B1/en';

export const SOFTORINO_WEBSITE_LINK = 'https://softorino.com/';

export const EBOOK_PDF_LINK =
  'https://storage.googleapis.com/cosupport-strapi-bucket/CoSupport_e-book.pdf';

export const BLACK_FRIDAY_DATE = '2024-12-06T23:59:59';
