export const SelfSufficientAi = ({ height = '25', width = '25', className = '' }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="25" rx="4" fill="#A498FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 8C20 6.89543 19.1046 6 18 6L8 6C6.89543 6 6 6.89543 6 8L6 15.1348C6 16.2394 6.89543 17.1348 8 17.1348L11.1595 17.1348L13.0654 19.0003L14.9714 17.1348L18 17.1348C19.1046 17.1348 20 16.2394 20 15.1348L20 8Z"
        fill="#05050D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4182 12.0999C17.781 11.7615 17.781 11.213 17.4182 10.8746C17.0555 10.5363 16.4673 10.5363 16.1046 10.8746C15.7418 11.213 15.7418 11.7615 16.1046 12.0999C16.4673 12.4382 17.0555 12.4382 17.4182 12.0999ZM13.5721 10.8747C13.9347 11.2131 13.9347 11.7619 13.5721 12.1003C13.2094 12.4387 12.6214 12.4387 12.2588 12.1003C11.8961 11.7619 11.8961 11.2131 12.2588 10.8747C12.6214 10.5362 13.2094 10.5362 13.5721 10.8747ZM9.93672 10.8744C10.2994 11.2129 10.2994 11.7616 9.93672 12.1001C9.57406 12.4385 8.98607 12.4385 8.62342 12.1001C8.26076 11.7616 8.26076 11.2129 8.62342 10.8744C8.98608 10.536 9.57406 10.536 9.93672 10.8744Z"
        fill="#A498FF"
      />
    </svg>
  );
};
