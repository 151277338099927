import React from 'react';
import { motion } from 'framer-motion';

type Props = {
  currentSlide: number;
  isLgUp?: boolean;
};
type EllipseProps = {
  delay?: number;
  x?: number[];
  y?: number[];
  scale?: number[];
  className?: string;
};

const GreenEllipse = ({
  y = [-150, 100, -150],
  x = [-150, 0, -150],
  scale = [1, 0.8, 1],
  delay = 1,
  className,
}: EllipseProps) => {
  return (
    <motion.div
      className={className}
      animate={{
        scale: scale,
        x: x,
        y: y,
      }}
      transition={{
        duration: 10,
        delay: delay,
        repeat: Infinity,
        repeatType: 'loop',
        ease: 'easeInOut',
      }}
    >
      <div className="bg-green-default lg:w-[21.528vw] w-[135px] h-[135px] lg:h-[21.528vw] rounded-full lg:blur-[450px]" />
    </motion.div>
  );
};

const PurpleEllipse = ({
  y = [-150, 0, -150],
  x = [-250, 50, -250],
  scale = [1, 0.8, 1],
  delay = 3,
  className,
}: EllipseProps) => {
  return (
    <motion.div
      className={className}
      animate={{
        scale: scale,
        x: x,
        y: y,
      }}
      transition={{
        duration: 10,
        delay: delay,
        repeat: Infinity,
        repeatType: 'loop',
        ease: 'easeInOut',
      }}
    >
      <div className="bg-purple lg:w-[21.528vw] w-[135px] h-[135px] lg:h-[21.528vw] rounded-full lg:blur-[450px]" />
    </motion.div>
  );
};

const BlueEllipse = ({
  y = [-100, 0, -100],
  x = [-150, 50, -150],
  scale = [1, 0.8, 1],
  delay = 3,
  className,
}: EllipseProps) => {
  return (
    <motion.div
      className={className}
      animate={{
        scale: scale,
        x: x,
        y: y,
      }}
      transition={{
        duration: 10,
        delay: delay,
        repeat: Infinity,
        repeatType: 'loop',
        ease: 'easeInOut',
      }}
    >
      <div className="bg-malibu lg:w-[21.528vw] w-[135px] h-[135px] lg:h-[21.528vw] rounded-full lg:blur-[450px]" />
    </motion.div>
  );
};

const YellowEllipse = ({
  y = [-100, 0, -100],
  x = [-150, 50, -150],
  scale = [1, 0.8, 1],
  delay = 3,
  className,
}: EllipseProps) => {
  return (
    <motion.div
      className={className}
      animate={{
        scale: scale,
        x: x,
        y: y,
      }}
      transition={{
        duration: 10,
        delay: delay,
        repeat: Infinity,
        repeatType: 'loop',
        ease: 'easeInOut',
      }}
    >
      <div className="bg-chartreuse_yellow lg:w-[21.528vw] w-[135px] h-[135px] lg:h-[21.528vw] rounded-full lg:blur-[450px]" />
    </motion.div>
  );
};

export const SectionBackgroundAnimation = ({ currentSlide }: Props) => {
  return (
    <div className="absolute lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:top-0 top-1/2 w-full">
      <motion.div
        className="absolute right-1/3 max-lg:scale-150"
        animate={{
          opacity: currentSlide === 0 ? 1 : 0,
          display: currentSlide === 0 ? 'block' : 'none',
        }}
        transition={{ duration: 1 }}
      >
        <GreenEllipse />
        <PurpleEllipse className="z-50" />
        <GreenEllipse
          className="absolute top-1/2 -right-full"
          scale={[0.6, 0.4, 0.6]}
          y={[-300, 0, -300]}
          x={[-200, 100, -200]}
        />
      </motion.div>
      <motion.div
        className="absolute right-1/3 max-lg:scale-150"
        animate={{
          opacity: currentSlide === 1 ? 1 : 0,
          display: currentSlide === 1 ? 'block' : 'none',
        }}
        transition={{ duration: 1 }}
      >
        <PurpleEllipse className="absolute top-1/3 -right-full z-50" />
        <BlueEllipse scale={[0.8, 0.6, 0.8]} y={[100, 0, 100]} x={[-100, 0, -100]} />
        <BlueEllipse scale={[0.5, 0.3, 0.5]} y={[-100, 0, -100]} x={[-100, 0, -100]} />
      </motion.div>
      <motion.div
        className="absolute right-1/3 max-lg:scale-150"
        animate={{
          opacity: currentSlide === 2 ? 1 : 0,
          display: currentSlide === 2 ? 'block' : 'none',
        }}
        transition={{ duration: 1 }}
      >
        <YellowEllipse scale={[1, 0.8, 1]} y={[-150, -100, -150]} x={[-100, 0, -100]} />
        <BlueEllipse scale={[0.8, 0.6, 0.8]} className="z-50" />
        <GreenEllipse
          className="absolute top-1/2 -right-full"
          scale={[0.8, 0.6, 0.8]}
          y={[-300, -100, -300]}
          x={[-100, 0, -100]}
        />
      </motion.div>
    </div>
  );
};
