import React, { useState } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { AnimatePresence, motion } from 'framer-motion';
import { clsx } from 'clsx';
import { TextBlock } from './TextBlock';
import { SectionBackgroundAnimation } from './SectionBackgroundAnimation';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { COLORS } from '@/constants/colors';
import { ImagesPart } from '@/components/PagesSections/MainPageSections/SecondSection/ImagesPart';

const SectionContainer = styled.div({
  width: '100%',
  position: 'relative',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
});

const PresentationBlock = dynamic(() => import('./PresentationBlock'), {
  ssr: false,
});

const MENU_NAV = [
  { id: 0, label: 'Automate Interactions' },
  { id: 1, label: 'Boost Support Team Productivity' },
  { id: 2, label: 'Leverage Customer Correspondence' },
];

export const SecondSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [hoverCurrentSlide, setHoverCurrentSlide] = useState<number | null>(0);
  const isLgUp = useMediaQuery('(min-width: 1024px)');

  const handleChangeActiveTab = (id: number | string) => {
    setCurrentSlide(Number(id));
  };

  const handleHoverTab = (id: number | null) => {
    setHoverCurrentSlide(id);
  };

  return (
    <SectionContainer className="z-10 bg-light md:pt-10-vw pt-6">
      <Container className="relative">
        <ImagesPart />
        <div className="relative">
          {isLgUp && (
            <div className="w-full flex justify-between divide-x absolute left-0 -z-50">
              {MENU_NAV.map((item) => {
                return (
                  <div key={item.id} className="px-12.5-vw -mb-1 w-full relative">
                    <Typography
                      $weight="600"
                      $type="PMedium"
                      className="font-jamjuree md:py-5-vw border-b-4 border-green-default text-center opacity-0"
                    >
                      {item.label}
                    </Typography>
                    <motion.div
                      className="w-40-vw h-40-vw bg-green-default blur-[50px] rounded-full left-1/2 transform -translate-x-1/2  absolute top-0"
                      animate={{
                        opacity: currentSlide === item.id ? 1 : 0,
                        display: currentSlide === item.id ? 'block' : 'none',
                      }}
                      transition={{ duration: 0.3 }}
                    />
                    <motion.div
                      className="w-20-vw h-20-vw bg-green-default blur-[50px] rounded-full left-1/2 transform -translate-x-1/2  absolute top-0"
                      animate={{
                        opacity: hoverCurrentSlide === item.id ? 0.8 : 0,
                        display: hoverCurrentSlide === item.id ? 'block' : 'none',
                      }}
                      transition={{ duration: 0.3 }}
                    />
                  </div>
                );
              })}
            </div>
          )}
          <div className="bg-[#FEFFFFB2] z-50 backdrop-blur-xl shadow-md border border-b-0 border-white lg:rounded-t-[30px] max-lg:rounded-md-10 max-lg:mb-8 lg:px-4-vw overflow-hidden">
            {isLgUp ? (
              <>
                <div className="w-full flex justify-between relative z-50">
                  {MENU_NAV.map((item, index) => {
                    const isLast = MENU_NAV.length === index + 1;
                    return (
                      <button
                        onMouseEnter={() => handleHoverTab(item.id)}
                        onMouseLeave={() => handleHoverTab(null)}
                        key={item.id}
                        className="px-12.5-vw -mb-1 w-full relative cursor-pointer"
                        onClick={() => handleChangeActiveTab(item.id)}
                      >
                        {!isLast && (
                          <div className="w-px bg-[#C7CFD3] absolute h-4/6 right-0 top-1/2 transform -translate-y-1/2" />
                        )}
                        <Typography
                          $weight="600"
                          $type="PMedium"
                          className={clsx(
                            currentSlide === item.id
                              ? 'border-green-default'
                              : 'border-transparent',
                            'font-jamjuree md:py-5-vw text-center border-b-4'
                          )}
                        >
                          {item.label}
                        </Typography>
                      </button>
                    );
                  })}
                </div>
                <div className="relative bg-ebony overflow-hidden rounded-t-lg-20">
                  <SectionBackgroundAnimation currentSlide={currentSlide} />
                  <div className="z-10 relative flex items-center justify-between p-7.5-vw gap-5-vw">
                    <AnimatePresence mode="wait">
                      <motion.div
                        key={currentSlide}
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -50 }}
                        transition={{ duration: 0.5 }}
                        className="flex flex-col md:gap-10-vw justify-center lg:pl-20-vw max-lg:w-full"
                      >
                        <TextBlock currentSlide={currentSlide} />
                      </motion.div>
                    </AnimatePresence>{' '}
                    <AnimatePresence mode="wait">
                      <motion.div
                        key={currentSlide}
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: 50 }}
                        transition={{ duration: 0.5 }}
                        className="flex flex-col gap-10 justify-center items-center lg:max-w-[39.583vw] w-full"
                      >
                        <PresentationBlock
                          currentSlide={currentSlide}
                          setCurrentSlide={setCurrentSlide}
                        />
                      </motion.div>
                    </AnimatePresence>
                  </div>
                </div>
              </>
            ) : (
              <Accordion
                type="single"
                defaultValue={String(currentSlide)}
                onValueChange={handleChangeActiveTab}
                collapsible
              >
                {MENU_NAV.map((el) => {
                  return (
                    <AccordionItem
                      key={el.id}
                      value={String(el.id)}
                      className="border-white last:border-none [&[data-state=open]>h3]:border-b-green-default [&[data-state=open]>h3]:border-b"
                    >
                      <AccordionTrigger className="[&[data-state=closed]>div]:opacity-100 px-4 [&[data-state=open]>svg>path]:stroke-green-default [&>svg>circle]:fill-[#E1EDEB] gap-3">
                        <Typography
                          $type="PMedium"
                          $weight="600"
                          className="font-jamjuree opacity-100 text-start"
                          $color={COLORS.EBONY}
                        >
                          {el.label}
                        </Typography>
                      </AccordionTrigger>
                      <AccordionContent className="pb-0">
                        <div className="bg-ebony overflow-hidden relative">
                          <SectionBackgroundAnimation currentSlide={currentSlide} />
                          <div className="backdrop-blur-[150px] relative z-10 flex flex-col items-start justify-between gap-8 px-5 py-8">
                            <TextBlock currentSlide={currentSlide} />
                            <div className="flex flex-col gap-10 justify-center items-center lg:max-w-[39.583vw] w-full">
                              <PresentationBlock
                                currentSlide={currentSlide}
                                setCurrentSlide={setCurrentSlide}
                              />
                            </div>
                          </div>
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            )}
          </div>
        </div>
      </Container>
    </SectionContainer>
  );
};
