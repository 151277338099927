import { PATENT_LINK } from '@/constants/links';
import React from 'react';
import Link from 'next/link';
import { PATH_DEMO } from '@/constants/spa-routes';

export const FAQMainPageList = [
  {
    question: 'What is generative AI for customer support, and how can businesses benefit from it?',
    answer: (
      <div>
        <p>
          A generative AI solution for customer support is a suite of technologies that, when
          applied to the customer service domain, enhance performance and cost-efficiency. The term
          “generative AI” is related to the Artificial Intelligence’s ability to produce original
          content in the form of a text, image, or voice message.
        </p>
        <p>
          The primary task of generative AI solutions for customer support built on generative AI
          technologies is routine task automation. Below are a few use cases on how AI for customer
          support handles this task:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <p>Automates the resolution process for a portion of support workflow;</p>
          </li>
          <li>
            <p>Triages support requests;</p>
          </li>
          <li>
            <p>Provides customer support agents with reply suggestions;</p>
          </li>
          <li>
            <p>Analyzes customer correspondence.</p>
          </li>
        </ul>
        <p>
          An AI solution for customer support exists in various forms; however, the most widespread
          AI solution is AI-powered chatbots and assistants that communicate with customers in a
          human-like manner.
        </p>
        <p>
          AI tools for customer support like chatbots and assistants integrate with different
          channels of communication, such as email, social media, live chats, interactive voice
          response (IVR) systems, and self-service portals.
        </p>
        <p>
          The integration of generative AI customer support solutions results in response time
          reduction, rate of resolved tickets increases, the reduction in operating costs, and other
          enhancements to customer service-related metrics. 
        </p>
        <p>
          The final goal of an AI integration to customer support solution is the enhanced customer
          experience and a higher level of customer satisfaction.
        </p>
      </div>
    ),
  },
  {
    question: 'How can your business benefit from CoSupport AI tools for customer support?',
    answer: (
      <div>
        <p>
          By implementing CoSupport AI tools for customer support, a business benefits from all the
          capabilities that a generative AI solution offers in this domain . These capabilities are
          as follows:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <p>
              response automation based on the innovative approaches that ensures answers precision,
              relevancy, and personalization;
            </p>
          </li>
          <li>
            <p>
              assistance for customer support agents with up to 80% automation, allowing human
              agents to curate response content;
            </p>
          </li>
          <li>
            <p>
              AI integration with all popular tools and across different channels, such as CRMs,
              email, website chats, and social media;
            </p>
          </li>
          <li>
            <p>
              AI tool to analyze data for more efficient decision-making and improvements in
              customer service domain and business in general.
            </p>
          </li>
        </ul>
        <p>
          CoSupport AI for customer support products’ capabilities entails the following benefits
          for businesses:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <p>
              24/7 support that excludes human errors, lack of motivation, and distractors impact;
            </p>
          </li>
          <li>
            <p>
              considerable savings of time and effort, along with the easy scaling and delegation of
              routine tasks to AI assistants with minimal resource investment;
            </p>
          </li>
          <li>
            <p>
              Substantial reduction in costs in terms of  hiring staff and maintaining your
              operational processes. 
            </p>
          </li>
        </ul>
        <p>
          <a
            href="/articles/klarna-ai-wins-big"
            target="_blank"
            className="underline underline-offset-2 cursor-pointer"
          >
            Klarna’s case
          </a>{' '}
          is a good example of the business benefits achievable through generative AI for customer
          support.. Klarna achieved a reduction of response time from 11 minutes to 2 and a decrease
          in repeated queries by 25%. The company  announced that they expected a profit increase of
          $40 million due to AI for customer support solutions integration.
        </p>
      </div>
    ),
  },
  {
    question: 'Why are CoSupport AI tools for customer support different from other solutions?',
    answer: (
      <div>
        <p>
          CoSupport AI tools for customer support are based on what the team calls native AI. It’s a
          suite of distinct approaches that allows for building deeply custom products.
        </p>
        <p>
          Customization is treated not only in general terms, meaning that products are tailored to
          the needs of a particular company. Customization also implies that the opportunity to
          adjust a solution to a particular business are wider compared to the solutions that are
          based on the ready-to-use products, such as well-known LLMs. 
        </p>
        <p>
          CoSupport AI builds solutions, adjusting key generative AI models parameters and
          influencing the outcome directly. The cornerstones of the solution outcomes are as
          follows:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <p>response speed;</p>
          </li>
          <li>
            <p>response precision;</p>
          </li>
          <li>
            <p>data security.</p>
          </li>
        </ul>
        <p>
          The core of the approach that aims at deep customization is an ensemble of AI models,
          including models trained from scratch for high performance in the generative AI customer
          support domain and solid security. 
        </p>
        <p>Here are four examples:</p>
        <ul className="list-disc pl-5">
          <li>
            <p>
              CoSupport AI uses the{' '}
              <a
                href={PATENT_LINK}
                target="_blank"
                rel="noreferrer"
                className="underline underline-offset-2 cursor-pointer"
              >
                patented in the U.S.
              </a>{' '}
              response acceleration architecture that allows one of the solutions, CoSupport Agent,
              to provide{' '}
              <a
                href="/articles/cosupport-ai-patent"
                target="_blank"
                className="underline underline-offset-2 cursor-pointer"
              >
                three times faster response
              </a>{' '}
              compared to GPT;
            </p>
          </li>
          <li>
            <p>
              the training data involved in a particular project never overlaps with the data
              related to other projects that provides close to zero risk of data leakage;
            </p>
          </li>
          <li>
            <p>
              Intelligent Embedding approach, based on the embedding that are performed by a
              dedicated model, lay the foundation for a high response precision;
            </p>
          </li>
          <li>
            <p>
              databases for back-end operations are organized in a way that provides close to zero
              risk of jailbreaking - the intrusion to the models’ “brains” for stealing sensitive
              data.
            </p>
          </li>
        </ul>
        <p>
          The CoSupport AI team has been working on distinct solutions development since [figure]
          year. Today, it invests all its experience and expertise in the creation of highly
          performative AI tools for customer support.
        </p>
      </div>
    ),
  },
  {
    question:
      'What do I need to implement AI for customer support solutions with CoSupport AI as a partner?',
    answer: (
      <div>
        <p>
          There aren’t any special requirements to start implementing generative AI customer support
          solutions in a business. However, we  recommend you use a properly structured,
          comprehensive knowledge base for training the generative AI models.  Ask us for detailed
          recommendations on structuring your knowledge base for the most productive outcomes.
        </p>
        <p>
          For two of CoSupport AI’s solutions, CoSupport Agent and CoSupport Customer, we’ll ask you
          for access to the databases related to the processes involved in a project. We need this
          access to customize your AI for customer support solutions. 
        </p>
        <p>
          CoSupport AI uses the CoSupport AI Data Grid to keep your data safe - an approach to
          keeping and processing your data that minimizes the data loss and jailbreaking. All data
          is kept and processed on dedicated servers.
        </p>
      </div>
    ),
  },
  {
    question: 'What workflow automation solutions does CoSupport AI offer for customer support?',
    answer: (
      <div>
        <p>
          CoSupport AI's solutions provide AI for customer support solutions, automating 80% to 100%
          of response and data processing tasks. At the same time, the extent of automation changes
          depending on a particular solution’s task:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <p>
              CoSupport Agent provides up to 80% of response automation since the solution’s task is
              customer service agent assistance. By providing agents with response drafts, the
              generative AI assistant decreases response time and enhances the fraction of resolved
              issues. , However, the beauty of these AI for customer support solutions is that the
              final decision on the response content is made by an agent.
            </p>
          </li>
          <li>
            <p>
              CoSupport Customer provides up to 100% of response automation. The AI for customer
              support solution is developed to enhance the volume of processed conversations
              significantly and to scale up customer service rapidly and resource-efficiently.
            </p>
          </li>
        </ul>
        <p>
          The third AI for customer support solution in CoSupport AI’s portfolio, CoSupport BI, is
          hard to evaluate in terms of automation since its goal isn't response automation but
          delivering data for business insights through an AI chatbot interface.
        </p>
        <br />
        <br />
        <p>
          That said, business leaders can customize reports in a way that was previously impossible.
          You can have the data you need to make decisions within seconds.
        </p>
        <br />
        <br />
        <p>
          Which generative AI solution is right for you? A few of our clients start with CoSupport
          Agent because they want to test-drive the system. When they see how accurate our responses
          are, they invariably convert to CoSupport Customer. Over 76% of our customers go on to
          sign up for CoSupport BI.
        </p>
        <br />
        <br />
        <p>Set up a consultation for a demo of each solution and to discuss your needs.</p>
      </div>
    ),
  },
  {
    question: 'Which tools can I integrate CoSupport AI solutions with?',
    answer: (
      <div>
        <p>
          CoSupport AI for customer support solutions integrate with all the popular  tools for
          customer service agents and team leads. Here is the list of possible integrations:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <p>
              CoSupport Agent integrates with popular CRM systems, such as Zendesk or Freshdesk;
              integration with a custom CRM is also possible;
            </p>
          </li>
          <li>
            <p>
              CoSupport Customer integrates with various communication channels like email, social
              media, or website and application chats;
            </p>
          </li>
          <li>
            <p>
              CoSupport BI integrates with business communication tools, such as Slack or Microsoft
              Teams; on the back-end part, it integrates with all possible sources of data,
              including databases, internal documentation, knowledge bases, and others.
            </p>
          </li>
        </ul>
        <p>
          If you’re not sure if any  of the CoSupport AI for customer support solutions products
          will integrate with your favorite tools, contact our team, and we’ll apply our expertise
          to find a solution.
        </p>
      </div>
    ),
  },
  {
    question: 'How do I get a demo of any of CoSupport AI’s products?',
    answer: (
      <div>
        <p>You can see us demonstrate your chosen solution by:</p>
        <ul className="list-disc pl-5">
          <li>
            <p>Leave your email address and the demo request in the chat;</p>
          </li>
          <li>
            <p>
              Submit the{' '}
              <Link href={PATH_DEMO.ROOT} prefetch={false}>
                Demo Request Form
              </Link>
              .
            </p>
          </li>
        </ul>
        <p>
          Email CoSupport AI at <a href="mailto:contact@cosupports.ai">contact@cosupports.ai</a>.
        </p>
      </div>
    ),
  },
];
