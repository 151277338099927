'use client';
import React, { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import BaseReactPlayer, { BaseReactPlayerProps } from 'react-player/types/base';
import { PlayButton } from '@/components/ReactVideoComponent/PlayButton';
import { clsx } from 'clsx';

type YoutubeVideoComponentProps = BaseReactPlayerProps & {
  videoUrl: string;
  poster?: string;
  className?: string;
};

export const ReactVideoComponent = forwardRef(
  (
    {
      videoUrl,
      pip = false,
      className,
      playing = true,
      controls = false,
      light = false,
      loop = true,
      playbackRate,
      volume,
      muted = true,
      onClick,
      onPause,
      onBuffer,
      onPlaybackRateChange,
      onError,
      onProgress,
      onDuration,
      onSeek,
      onStart,
      onEnded,
      onReady,
      onDisablePIP,
      onEnablePIP,
      onPlaybackQualityChange,
    }: Omit<YoutubeVideoComponentProps, 'ref'>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [hasWindow, setHasWindow] = useState(false);
    useEffect(() => {
      if (typeof window !== 'undefined') {
        setHasWindow(true);
      }
    }, []);
    const playerRef = useRef(null);
    const [isMuted, setIsMuted] = useState<boolean>(muted);
    const [isPlaying, setIsPlaying] = useState<boolean>(playing);

    useEffect(() => {
      setIsPlaying(playing);
      !playing && setIsMuted(true);
    }, [playing]);

    return (
      <div ref={ref} className={clsx(className, 'w-full md:px-[72px]')}>
        <div className="md:rounded-3xl md:object-fill md:w-full w-full aspect-video rounded-md-10 overflow-hidden z-50 relative flex justify-center">
          {isMuted && (
            <PlayButton
              onClick={() => {
                setIsPlaying(true);
                setIsMuted(false);
                onClick && onClick(playerRef?.current);
              }}
            />
          )}
          {hasWindow && (
            <ReactPlayer
              ref={playerRef}
              className="react-player"
              width="100%"
              height="100%"
              url={videoUrl}
              pip={pip}
              playing={isPlaying}
              controls={controls}
              light={light}
              loop={loop}
              playbackRate={playbackRate}
              volume={volume}
              muted={isMuted}
              onReady={onReady}
              onStart={onStart}
              onEnablePIP={onEnablePIP}
              onDisablePIP={onDisablePIP}
              onPause={onPause}
              onBuffer={onBuffer}
              onPlaybackRateChange={onPlaybackQualityChange}
              onSeek={onSeek}
              onEnded={onEnded}
              onError={onError}
              onProgress={onProgress}
              onDuration={onDuration}
              onPlaybackQualityChange={onPlaybackRateChange}
              config={{
                youtube: {
                  playerVars: { rel: 0, playsinline: 1 },
                },
              }}
            />
          )}
        </div>
      </div>
    );
  }
);
