import React from 'react';
import Link from 'next/link';
import { CoSupportLogo } from '@/assets/icons/CoSupportLogo';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';
import AwardsSection from '@/components/Footer/AwardsSection';

export default function DescriptionWithIconsSection() {
  return (
    <div className="flex lg:w-5/12 flex-col md:gap-7.5 gap-6">
      <div className="flex flex-col md:gap-5 gap-4">
        <Link href="/" prefetch={false}>
          <CoSupportLogo className="fill-white" />
        </Link>
        <Typography
          $type="PSmall"
          $color={COLORS.WHITE}
          className="opacity-50 font-jamjuree"
          $weight="500"
        >
          CoSupport AI copilot solution boosts customer support team productivity, fully automates
          customer requests, and offers remarkable business insights with GenAI power.
        </Typography>
        <AwardsSection className="mt-2.5" />
      </div>
    </div>
  );
}
