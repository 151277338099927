import React from 'react';
import { Typography } from '@/components/Typography/Typography';
import { clsx } from 'clsx';

type Props = {
  className?: string;
  data: {
    title: string;
    description: React.ReactNode | string;
    icon: React.ReactNode;
  };
};
export const Card = ({ data, className }: Props) => {
  return (
    <div
      className={clsx(
        className,
        'relative md:p-5-vw p-4 flex flex-col md:gap-3.5-vw gap-4 border border-[#C7CFD380] rounded-2xl'
      )}
    >
      <div>{data.icon}</div>
      <div className="flex flex-col gap-1">
        <Typography $type="PMedium" $weight="600" className="font-jamjuree italic">
          {data.title}
        </Typography>
        <Typography $type="PMedium" $weight="400" className="font-jamjuree opacity-70">
          {data.description}
        </Typography>
      </div>
    </div>
  );
};
