import React from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { AIDeliversCarousel } from './AIDeliversCarousel';

export const CoSupportAIDelivers = () => {
  return (
    <div className="relative bg-white">
      <Container className="md:py-24.5-vw py-10 pb-16 relative">
        <section className="flex flex-col md:gap-5-vw gap-4">
          <div>
            <Typography $type="Heading2Big" className="font-formula md:w-7/12" $weight="700">
              CoSupport AI Delivers Enterprise-Grade Tailored Solutions Across Industries
            </Typography>
          </div>
          <AIDeliversCarousel />
        </section>
      </Container>
    </div>
  );
};
